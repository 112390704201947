<template>
  <!-- <ButtonDefinitive
    label="Continuar"
    @click="callValidateStep1"
  /> -->
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "Step1-Footer",
  components: { ButtonDefinitive },
  methods: {
    callValidateStep1() {
      this.$eventBus.$emit("validateScharffPointStep1");      
    },
  },
};
</script>

<style></style>
