<template>
  <div class="c-slider pt-0">
    <VueSlickCarousel v-bind="settingSlider">
      <div class="c-slider_item">
        <img src="~@/assets/img/service-cart-welcome.png" />
        <div>
          {{ textCarousel }}
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  data() {
    return {
      settingSlider: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 6000,
        cssEase: "linear",
      },
    };
  },
  components: {
    VueSlickCarousel,
  },
  methods: {},
  mounted() {},
  computed: {
    type() {
      return this.$store.getters.SCHARFFPOINT_ORDER_TYPE;
    },
    progressNav() {
      return this.$store.getters.SCHARFFPOINT_PROGRESS_NAV;
    },
    textCarousel() {
      switch (this.progressNav.step) {
        case 1:
          return "Elige el tipo de envío que se adecue a tí, llévalo a uno de nuestros puntos o lo recogemos en tu domicilio.";

        case 2:
          return this.type === 1
            ? "¿En qué punto dejarás tu paquete?"
            : "¿En qué punto recogerán tu paquete?";

        case 3:
          return "Recuerda que puedes agregar 1 paquete o hasta 100 paquetes por carga.";

        case 4:
          return "Chequea y verifica el resumen de tu envío.";

        default:
          return "Envíos o recojos a través de nuestros puntos. Acércate a un Punto Sharf y llega Lima & provincias. ¡Tenemos más de 250 puntos en todo el país!";
      }
    },
  },
};
</script>
