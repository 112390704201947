<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <ButtonDefinitive
      label='Descargar guía'
      @click="downloadFile"
    />
    <ButtonDefinitive
      variant='ghost'
      label='Ir al inicio'
      @click="sendToHome"
    />
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import { downloadGuide } from "../../../services/guias";

export default {
  name: "OrderConfirmationFooter",
  components: {
    ButtonDefinitive,
  },
  methods: {
    sendToTracking() {
      window.location.href = `${process.env.VUE_APP_WEBURL}mis-envios`;
    },
    sendToHome() {
      this.$store.dispatch("STYLE_HOME_DIV");
      window.location.href = process.env.VUE_APP_WEBURL;
    },
    async downloadFile() {
      try {
        this.$swal.showLoading();
        await downloadGuide(
          this.payment.orderId,
          `Punto Sharf ${this.payment.orderId}`
        );
        this.$swal.close();
        this.trackEvent('click_puntos_descargar_guia', 'puntos')
      } catch (error) {
        this.showError("Error al descargar la guia");
        console.error(error);
        this.trackEvent('click_puntos_descargar_guia_error', 'puntos')
      }
    },
  },
  computed: {
    payment() {
      return this.$store.getters.PAYMENT_RESULT;
    }
  },
};
</script>

<style>
.c-nav--floating-footer-sticky {
  display: flex;
  gap: 8px;
}
</style>
