<template>
  <article id='c-search-inputs' class='c-content--sendPoint c-scrollbar'>
    <div class='c-content--app_container'>
      <p class='text-xl-bold' style='padding-bottom: 16px'>¿Quién envía?</p>
      <DestinationContactButton
        @click="openContactModal('origin')"
        :destinationContact='originContact'
      />

      <p class='text-xl-bold' style='padding-bottom: 16px; padding-top: 48px;'>¿De dónde recogemos tus paquetes?</p>
      <SelectAddressButton
        @click='openAddressesModal()'
        :addressData='originAddressOfOrder'
      />

      <!-- <h2>Indicar lugar de recojo</h2>
      <div id="c-form-search-points" autocomplete="off">
        <input-district
          id="scharffpoint-step-2-originDistrict"
          text="Buscar distrito"
          @getDistrictId="setOriginDistrict"
          serviceType="2"
          v-on:focus="isOriginFocused = false"
          @input="districtId = 0"
        ></input-district>

        <div class="c-input input-field curso-pointer">
          <input
            ref="originAddress"
            type="text"
            v-model="originAddress.description"
            @focus="isOriginFocused = true"
          />

          <label for>Dirección</label>
          <div
            id="c-icon-favorite-1"
            class="c-input--icon btnOpenModalDirecitionClass"
            v-on:click="AddAddressFavorite('origin', null)"
          >
            <img
              v-if="originAddress.favorite === true"
              src="~@/assets/img/icon/icon-estrella-on.svg"
              alt="favorite"
              loading="lazy"
              class="btn-open-modal-direction"
            />
            <img
              v-else
              src="~@/assets/img/icon/icon-estrella-set.svg"
              alt="favorite"
              loading="lazy"
              class="btn-open-modal-direction"
            />
          </div>
        </div>
        <div class="c-input input-field mb-2">
          <input
            type="text"
            v-model="addressDetail"
            v-on:focus="isOriginFocused = false"
            placeholder="Acá puedes colocar todo el detalle de la dirección, incluyendo MZ o Lote"
          />
          <label for class="active">Referencia</label>
        </div>
        <h2>Datos de quien envía</h2>
        <article
          v-if="originContact === null"
          id="btnModalContactFavorite"
          class="c-grid--services__item pt-0 pb-0"
        >
          <a href="javascript:void(0)" @click="openContactModal('origin')"
            ><div id="" class="card c-card c-card--service-box d-flex p-1">
              <div class="card-image">
                <div class="card-title color-text-black">
                  <span class="card-sub-title color-text-black">Escoge tu</span
                  ><span class="card-option">contacto</span>
                </div>
              </div>
              <img
                src="~@/assets/img/service-point-sharff-3.svg"
                class="translatex"
              /></div
          ></a>
        </article>
        <div
          id="c-contact-send-1"
          class="c-btn-contact-click c-content--spacing-block curso-pointer"
          v-else
        >
          <div
            class="collection c-collection c-collection--not-hover c-collection--card"
          >
            <div
              class="collection-item collection-item--arrow-next collection-item--icon"
            >
              <a
                @click.prevent="editOriginContact"
                class="collection-item--icon collection-item--icon--user"
              >
                <div class="c-text-medium font-weight-bold">
                  {{ originContact.name }}
                </div>
                <div class="c-text-medium">N° {{ originContact.phone }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <favorite-address
        id="favoriteOriginAddress"
        v-show="isOriginFocused === true"
        @getSelectedAddress="getOriginSelectedAddress"
        modalId="modal-contact-origin"
      /> -->
    </div>
  </article>
</template>

<script>
import M from "materialize-css";

import DestinationContactButton from '../../Button/DestinationContactButton.vue'
import SelectAddressButton from '../../Button/SelectAddressButton.vue'

export default {
  name: "Step2-Type2-Content",
  components: {
    DestinationContactButton,
    SelectAddressButton
  },
  data() {
    return {
      isOriginFocused: false,
      originAddress: {
        description: "",
        lat: null,
        lng: null,
        favorite: false,
      },
      districtId: null,
      addressDetail: "",
    };
  },
  watch: {
    originAddress: {
      handler() {
        this.saveOriginAddress();
      },
      deep: true,
    },
  },
  methods: {
    AddAddressFavorite(type) {
      if (type === "origin") {
        this.$store.dispatch("HELPER_SET_ADD_ADDRESS", this.originAddress);
        this.originAddress.favorite = true;
      }
      const modalAddAddress = document.querySelector("#modal-add-address");
      const instance = M.Modal.init(modalAddAddress, { dismissible: false });
      instance.open();
    },
    editOriginContact() {
      this.openContactModal("origin");
      this.trackEvent('click_puntos_recojo_paso_2_editar_contacto_btn', 'pickup');
    },
    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type)

            const modalContactFavorite =document.querySelector("#modal-contact-origin")
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false,
            });
            instance.open()

          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });

      this.trackEvent('click_puntos_recojo_paso_2_contacto_btn', 'pickup');
    },
    async openAddressesModal() {
      this.showLoading();
      await this.$store.dispatch('GET_FAVORITE_LOCATIONS')
      const modalAddressFavorite = document.querySelector('#modal-favorite-address')
      const instance = M.Modal.init(modalAddressFavorite, {
        dismissible: false
      })
      instance.open()
      this.$swal.close()

      this.trackEvent('click_puntos_recojo_paso_2_direccion_origen', 'pickup')
    },
    setOriginDistrict(id) {
      this.districtId = id;
      this.$store.dispatch("SCHARFFPOINT_ORDER_ORIGIN_DISTRICT", id);
    },
    setAddressDetail() {
      this.$store.dispatch(
        "SCHARFFPOINT_ORDER_ORIGIN_ADDRESS_DETAIL",
        this.addressDetail
      );
    },
    saveOriginAddress() {
      this.$store.dispatch(
        "SCHARFFPOINT_ORDER_ORIGIN_ADDRESS",
        this.originAddress
      );
    },
    getOriginAddressData(addressData, placeResult) {
      this.originAddress.description = placeResult.formatted_address;
      this.originAddress.lat = addressData.latitude;
      this.originAddress.lng = addressData.longitude;
      this.isOriginFocused = false;
      this.map.setMarker(
        { Lat: addressData.latitude, Lng: addressData.longitude },
        null,
        true
      );

      this.saveOriginAddress();
    },
    getOriginSelectedAddress(favoriteLocation) {
      this.originAddress.description = favoriteLocation.address;
      this.originAddress.lat = favoriteLocation.location.Lat;
      this.originAddress.lng = favoriteLocation.location.Lng;
      this.$refs.originAddress.update(favoriteLocation.address);
      this.isOriginFocused = false;
      this.map.setMarker(favoriteLocation.location, null, true);

      this.saveOriginAddress();
    },
  },
  mounted() {
    this.$nextTick(() => {});
    this.$eventBus.$on("puntoScharffSetOriginAddressDetail", () => {
      this.setAddressDetail();
    });
  },
  computed: {
    originContact() {
      return this.$store.getters.SCHARFFPOINT_ORIGIN_CONTACT;
    },
    originAddressOfOrder() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN
    },
    map() {
      return this.$store.getters.SCHARFFPOINT_MAP;
    },
  },
};
</script>
