<!-- eslint-disable max-len -->
<template>
  <article class="c-content--sendPoint c-scrollbar">
    <div class="c-content--app_container">

      <div class="c-content--spacing-block" style='padding-bottom: 28px'>
        <div class='text-xl-bold' style='padding-bottom: 16px'>¿Quién envía?</div>
        <DestinationContactButton
          @click="openContactModal('origin')"
          :destinationContact="originContact"
          missingErrorMessage="Escoge un contacto."
        />
      </div>

      <!-- Dirección de recojo -->
      <div v-if='origin.scharffPoint' class='order_confirmed--info' style='padding-bottom: 28px'>
        <div class='text-xl-bold'>Dirección de recojo</div>
        <div style='display: flex; padding: 16px; flex-direction: column; gap: 12px; border-radius: 16px; border: 1px solid #D0D0D0'>
          <div class='text-m-bold'>{{ origin.scharffPoint.Name }}</div>
          <div class=''>
            <div class='text-s-regular'>{{ origin.scharffPoint.Address }}</div>
            <div class='text-s-regular' style='color: #737373;'>{{`Horario de atención: ${origin.scharffPoint.OpenTime.substring(0,5)} - ${origin.scharffPoint.CloseTime.substring(0, 5)}`}}</div>
          </div>
        </div>
      </div>
      <div v-else class='order_confirmed--info' style='padding-bottom: 28px'>
        <div class='text-xl-bold'>Dirección de recojo</div>
        <ChooseOptionButton :isSelectable='false'>
          <template v-slot:icon>
            <Icon name='Pin13' strokeColor='#FF6568' />
          </template>
          <template v-slot:content>
            <div>
              <div class='text-m-regular'>{{ origin.description }}</div>
              <div class='text-s-regular' style='color: #737373'>{{ origin.name }}</div>
            </div>
          </template>
        </ChooseOptionButton>
      </div>

      <div class="c-content--spacing-block">
        <div class='text-xl-bold'>Paquetes</div>
        <div class="c-content--scroll-package c-scrollbar overflow-x">
          <div
            v-for="(item, index) in lstDestination"
            v-bind:key="index"
            class="collection c-collection c-collection--not-hover c-collection--card c-collection--card__item border-none c-content--box-shadow"
          >
            <PackageSummary
              :includeDeleteButton='false'
              :packageDataIndex='index'
              :packageData='item'
              @onEdit="editDestinationAddress(item, index)"
            />
          </div>
        </div>
      </div>

      <div class="c-content--spacing-block" style='padding-bottom: 28px'>
        <div class='text-xl-bold' style='padding-bottom: 16px'>Tipo de comprobante</div>
        <ChooseOptionButton @click='openTypeVoucher'>
          <template v-slot:icon>
            <Icon name='Receipt-bill' strokeColor='#FF6568' />
          </template>
          <template v-slot:content>
            {{ !voucher ? "Escoge un comprobante" : voucher.typeVoucher }}
          </template>
        </ChooseOptionButton>
      </div>

      <div v-if="user && !user.esUsuarioProgramado && !isDestinationPaymentForAllPackages" class="c-content--spacing-block" style='padding-bottom: 28px'>
        <div class='text-xl-bold' style='padding-bottom: 16px;'>Método de pago</div>
        <ChooseOptionButton @click='openTypeCard'>
          <template v-slot:icon>
            <Icon name='BankCardDouble' strokeColor='#FF6568' />
          </template>
          <template v-slot:content>
            {{ !card ? 'Método de pago' : `${card.cardBrand} ${card.cardNumber}` }}
          </template>
        </ChooseOptionButton>
      </div>

      <div class="c-content--spacing-block">
        <div class="c-content--spacing-block" style='padding-bottom: 28px'>
          <div class='text-xl-bold' style='padding-bottom: 16px;'>¿Tienes un cupón de descuento?</div>
          <span v-if="coupon && coupon.maxUses !== 0">Este cupón tiene {{ coupon.maxUses - coupon.uses }} usos disponibles.</span>
          <ChooseOptionButton @click='openCoupon'>
            <template v-slot:icon>
              <Icon name='Discount-ticket' strokeColor='#FF6568' />
            </template>
            <template v-slot:content>
              {{ coupon ? `${coupon.code} (${coupon.discountMessage})` : 'Ingresa un cupón' }}
            </template>
          </ChooseOptionButton>
          <div v-if='coupon' style='text-decoration: underline; cursor: pointer' @click='deleteCoupon'>Eliminar cupón</div>
        </div>
      </div>

      <!-- Resumen de Pago -->
      <div class='c-content--spacing-block'>
        <div class='c-content--spacing-block' style='padding-bottom: 28px'>
          <div class='text-xl-bold' style='padding-bottom: 16px'>Resumen de Pago</div>

          <div style='display: flex; padding: 16px; gap: 16px; flex-direction: column; border-radius: 16px; border: 1px solid #D0D0D0'>

            <div v-if='isDestinationPaymentForAllPackages || isDestinationPaymentForSomePackages' style='display: flex; flex-direction: column; gap: 10px;'>
              <div class='text-s-regular' style='display: flex; justify-content: space-between'>
                <span>Subtotal a pagar al recibir:</span>
                <span>S/ {{ paidDestFareNoDiscount.toFixed(2) }}</span>
              </div>
              <div class='text-s-regular' style='display: flex; justify-content: space-between; color: green'>
                <span>Descuento aplicado:</span>
                <span>-S/ {{ coupon ? (paidDestFareNoDiscount - paidDestFareWithDiscount).toFixed(2) : '0.00' }}</span>
              </div>
              <div class='text-s-bold' style='display: flex; justify-content: space-between'>
                <span>Total a pagar al recibir:</span>
                <span>S/ {{ coupon ? paidDestFareWithDiscount.toFixed(2) : paidDestFareNoDiscount.toFixed(2) }}</span>
              </div>
            </div>

            <hr v-if='(isDestinationPaymentForAllPackages || isDestinationPaymentForSomePackages) && (isPrepaidForAllPackages || isPrepaidForSomePackages)' style='width: 100%; margin: 0'>

            <div v-if='isPrepaidForAllPackages || isPrepaidForSomePackages' style='display: flex; flex-direction: column; gap: 10px'>
              <div class='text-s-regular' style='display: flex; justify-content: space-between'>
                <span>Subtotal a pagar ahora:</span>
                <span>S/ {{ prepaidFareNoDiscount.toFixed(2) }}</span>
              </div>
              <div class='text-s-regular' style='display: flex; justify-content: space-between; color: green'>
                <span>Descuento aplicado:</span>
                <span>-S/ {{ coupon ? (prepaidFareNoDiscount - prepaidFareWithDiscount).toFixed(2) : '0.00' }}</span>
              </div>
              <div class='text-s-bold' style='display: flex; justify-content: space-between'>
                <span>Total a pagar ahora:</span>
                <span>S/ {{ coupon ? prepaidFareWithDiscount.toFixed(2) : prepaidFareNoDiscount.toFixed(2) }}</span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div style='display: flex; gap: 12px; padding-top: 8px; padding-bottom: 16px;'>
        <CustomCheckbox checkboxId='TYC' v-model='tyc' />
        <div v-if="type == 1" class='text-m-regular'>
          He leído y acepto:
          <a href="https://saio.holascharff.com/Files/terminos-y-condiciones-punto-scharff-drop-off.pdf" target="popup" onclick="window.open('https://saio.holascharff.com/Files/terminos-y-condiciones-punto-scharff-drop-off.pdf','popup','width=600,height=600'); return false;" style="text-decoration: underline" class='text-m-regular' >
            Términos y Condiciones de puntos
          </a>
        </div>
        <div v-else class='text-m-regular'>
          He leído y acepto:
          <a href="https://saio.holascharff.com/Files/terminos-y-condiciones-punto-scharff-pick-up.pdf" target="popup" onclick="window.open('https://saio.holascharff.com/Files/terminos-y-condiciones-punto-scharff-pick-up.pdf','popup','width=600,height=600'); return false;" style="text-decoration: underline" class='text-m-regular' >
            Términos y Condiciones de puntos
          </a>
        </div>
      </div>

    </div>
  </article>
</template>

<script>
import { createMultipedidoPuntoScharff } from "../../../services/client";
import {  getScharffPointFare} from "../../../services/fare"
import PackageSummary from '@/components/PackageSummary.vue'
import ChooseOptionButton from '@/components/Button/ChooseOptionButton.vue'
import Icon from '@/components/Icon.vue'
import CustomCheckbox from '@/components/Form/CustomCheckbox.vue'
import DestinationContactButton from '../../Button/DestinationContactButton.vue'

export default {
  name: "Step4Content",
  components: {
    PackageSummary,
    ChooseOptionButton,
    Icon,
    CustomCheckbox,
    DestinationContactButton,
  },
  data() {
    return {
      fare: 0,
      tyc: false,
    };
  },
  methods: {
    openPopupDropOff() {
      const url = "https://saio.holascharff.com/Files/terminos-y-condiciones-punto-scharff-drop-off.pdf";
      const options = "width=600,height=600";
      window.open(url, 'popup', options);
      this.trackEvent('click_puntos_envia_paso_4_ver_tyc', 'dropoff')
    },
    openPopupPickUp() {
      const url = "https://saio.holascharff.com/Files/terminos-y-condiciones-punto-scharff-pick-up.pdf";
      const options = "width=600,height=600";
      window.open(url, 'popup', options);
      this.trackEvent('click_puntos_recojo_paso_4_ver_tyc', 'pickup')
    },
    async fetchCards() {
      this.showLoading();
      await this.$store
        .dispatch("GET_CARDS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const cards = success.data.result;
            this.$store.dispatch("GENERAL_SET_CARDS", cards);
            if (cards.length > 0) {
              this.$store.dispatch("SCHARFFPOINT_CARD", cards[0]);
            }
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    setModel(origin, destination) {
      const fareRequest = {
        PackageValue: destination.productValue,
        PackageWidth: destination.packageSize.width,
        PackageHeight: destination.packageSize.height,
        PackageDeep: destination.packageSize.lengths,
        PackageWeight: destination.packageSize.weight,
        PackageType:
          destination.packageSize.type === "Pequeno"
            ? "Pequeño"
            : destination.packageSize.type,
        isMoreThan10: false,
        IsServiceExpress: false,
        IsServiceProgrammed: false,
        isServiceScharffPoint: true,
        OriginDistrictId: origin.districtId.id,
        DestinationDistrictId: destination.destinationAddress.districtId,
        ProgrammedServiceDate: null,
        IsUsingSubscription: false,
      };
      return fareRequest;
    },
    async loadFare() {
      
      let fare = 0;
      const { origin } = this;

      this.destinations.forEach((element) => {
        const fareRequest = {
          PackageValue: element.productValue,
          PackageWidth: element.packageSize.width,
          PackageHeight: element.packageSize.height,
          PackageDeep: element.packageSize.lengths,
          PackageWeight: element.packageSize.weight,
          PackageType: element.packageSize.type,
          isMoreThan10: false,
          IsServiceExpress: false,
          IsServiceProgrammed: false,
          isServiceScharffPoint: true,
          OriginDistrictId: origin.districtId.id,
          DestinationDistrictId: element.destinationAddress.districtId.id,
          ProgrammedServiceDate: null,
          IsUsingSubscription: false,
        };
        const responseFare = getScharffPointFare(fareRequest).then((response=>{
            fare += response.totalFare;
        }));

        //TODO: ELiminar al terminar
        // this.$store
        //   .dispatch("SCHARFFPOINT_CALCULATE_FARE", fareRequest)
        //   .then((response) => {
        //     fare += response.totalFare;
        //   })
        //   .catch((error) => {
        //     this.$swal({
        //       title: "Error!",
        //       text: error,
        //       icon: "error",
        //       confirmButtonText: "Aceptar",
        //     });
        //   });
      });

      this.fare = fare;
      this.$store.dispatch("SCHARFFPOINT_FARE", this.fare);
    },
    openModalSelectDate() {
      this.openModal("modal-date");
    },
    openTypeVoucher() {
      this.openModal("modalTypeVoucher");
      if(this.type == 1){
        this.trackEvent('click_puntos_envia_paso_4_comprobante', 'dropoff/puntoapunto')
      }else{
        this.trackEvent('click_puntos_recojo_paso_4_comprobante', 'pickup')
      }
    },
    openTypeCard() {
      this.openModal("modalTypeCard");
      if(this.type == 1){
      this.trackEvent('click_puntos_envia_paso_4_metodo_pago', 'dropoff/puntoapunto')
      }else{
        this.trackEvent('click_puntos_recojo_paso_4_metodo_pago', 'pickup')
      }
    },
    openTip() {
      this.openModal("modalTip");
    },
    openCoupon() {
      this.openModal("modalCoupon");

      if(this.type == 1){
      this.trackEvent('click_puntos_envia_paso_4_cupon', 'dropoff/puntoapunto')
      }else{
        this.trackEvent('click_puntos_recojo_paso_4_cupon', 'pickup')
      }
    },
    deleteCoupon() {
      this.$store.dispatch("SCHARFFPOINT_COUPON", null);
      const destinations = this.order.Destination;
      destinations.forEach((element) => {
        if (element.discountFare) {
          element.discountFare = null;
          element.coupon = null;
        }
      });
      this.$store.dispatch("SCHARFFPOINT_APPEND_DESTINATION", destinations);
      this.showLoading();
      const sumAll = this.order.Destination
        .filter(item => !item.isShippingPaidAtDestination)
        .map(item => item.discountFare ? parseFloat(item.discountFare) : item.fare)
        .reduce((prev, curr) => prev + curr, 0)
      this.$store.dispatch("SCHARFFPOINT_FARE", sumAll)
      this.$swal.close()

      if(this.type == 1){
      this.trackEvent('click_puntos_envia_paso_4_eliminar_cupon', 'dropoff/puntoapunto')
      }else{
        this.trackEvent('click_puntos_recojo_paso_4_eliminar_cupon', 'pickup')
      }
    },
    async validateStep4() {
      let isValid = true;

      if (!this.voucher) {
        this.showError("Por favor ingrese los datos del comprobante de pago.");
        isValid = false;
      }
      if (
        this.fareAmount !== 0 &&
        !this.card &&
        !this.user?.esUsuarioProgramado
      ) {
        this.showError("Por favor ingrese un método de pago.");
        isValid = false;
      }
      if (!this.tyc) {
        this.showError("Por favor acepte los términos y condiciones.");
        isValid = false;
      }
      if (isValid) {
        this.showLoading(
          "Estamos creando tu pedido. Ten en cuenta que esto puede tardar varios segundos dependiendo de la cantidad de destinos."
        );

        if(this.order?.card?.cardId == -1){
          this.trackEvent('click_paso_4_crear_pedido_pago_efectivo', 'programado')
        }else{
          this.trackEvent('click_paso_4_crear_pedido', 'programado')
        }

        if(this.type == 1){
          if(this.order?.card?.cardId == -1){
            this.trackEvent('click_puntos_envia_paso_4_crear_pedido_pago_efectivo', 'dropoff/puntoapunto')
          }else{
            this.trackEvent('click_puntos_envia_paso_4_crear_pedido', 'dropoff/puntoapunto')
          }

        }else{
          if(this.order?.card?.cardId == -1){
            this.trackEvent('click_puntos_recojo_paso_4_crear_pedido_pago_efectivo', 'pickup')
          }else{
            this.trackEvent('click_puntos_recojo_paso_4_crear_pedido', 'pickup')
          }
        }

        await this.createOrder();
      }
    },
    async createOrder() {
        this.$store
        .dispatch("SCHARFFPOINT_CREATE_FARE")
        .then(async (data) => {
          const response = await createMultipedidoPuntoScharff(data);
          console.log(response)
          this.$swal.close();
          if (response.status >= 200 && response.status < 400) {
            this.$store.dispatch("SET_PAYMENT_RESULT", response.data);
            this.$store.dispatch("SCHARFFPOINT_PROGRESS_NAV_STEP", 5);
            this.$store.dispatch("STYLE_HOME_DIV");
            this.$store.dispatch("STYLE_EXPRESS_STEP_DIV_W_BG");

            if (response.data.extraParameters) {
              if(this.type == 1){
                this.trackEvent('click_puntos_envia_paso_4_crear_pedido_pago_efectivo_success', 'dropoff/puntoapunto')
              }else{
                this.trackEvent('click_puntos_recojo_paso_4_crear_pedido_pago_efectivo_success', 'pickup')
              }

              this.$store.dispatch("HELPER_SET_SERVICE_TYPE_FOR_SURVEY", 'Puntos Scharff')
              var pagoEfectivoLink = response.data.extraParameters.URL_PAYMENT_RECEIPT_HTML;
              if(pagoEfectivoLink){
                window.open(pagoEfectivoLink, '_blank');
              }
            }

            if (response.canTakeSurvey) {
              const modal = document.querySelector("#modalProductMarketFitSurvey")
              const instance = M.Modal.init(modal, {
                dismissible: false
              })
              if (instance) instance.open()
            }

            return true;
          }

          if(this.type == 1){
                this.trackEvent('click_puntos_envia_paso_4_crear_pedido_error', 'dropoff/puntoapunto')
          }else{
            this.trackEvent('click_puntos_recojo_paso_4_crear_pedido_error', 'pickup')
          }
          this.showError(
            response.data.message || response.data.title ||
              "Ocurrio un error inesperado, inicie sesión nuevamente."
          );
          return false;
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: Step4-Content.vue:418 ~ createOrder ~ error",
            error
          );
          this.showError(error?.data?.message || "Ocurrio un error.");
        });
    },
    editDestinationAddress(destination, index) {
      this.$store.dispatch("SCHARFFPOINT_ORDER_SET_INDEX_DESTINATION", index);
      this.$eventBus.$emit("scharffPointSetDataDestinationModal", destination);
      const modalpackage = document.querySelector("#modal-package");
      modalpackage.classList.add("is-active");
      document.getElementById("scharffPoint-step-3-modal-pop").value =
        destination.destinationAddress.districtId.text;

      if(this.type == 1){
        this.trackEvent('click_puntos_envia_paso_4_editar_paquete', 'dropoff/puntoapunto')
      }else{
        this.trackEvent('click_puntos_recojo_paso_4_editar_paquete', 'pickup')
      }
    },

    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);

            const modalContactFavorite = document.querySelector("#modal-contact-origin")
            const instance = M.Modal.init(modalContactFavorite, { dismissible: false })
            instance.open()

          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
        this.trackEvent('click_puntos_envia_paso_2_contacto_btn', 'dropoff');
    },
  },
  computed: {
    origin() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    voucher() {
      return this.$store.getters.SCHARFFPOINT_VOUCHER;
    },
    card() {
      return this.$store.getters.SCHARFFPOINT_CARD;
    },
    coupon() {
      return this.$store.getters.SCHARFFPOINT_COUPON;
    },
    lstDestination() {
      return this.$store.getters.SCHARFFPOINT_ORDER_DESTINATION;
    },
    type() {
      return this.$store.getters.SCHARFFPOINT_ORDER_TYPE;
    },
    order() {
      return this.$store.getters.PROGRAMMED_ORDER;
    },
    fareAmount() {
      return this.$store.getters.SCHARFFPOINT_FARE;
    },
    user() {
      return this.$store.getters.USER;
    },
    originContact() {
      return this.$store.getters.SCHARFFPOINT_ORIGIN_CONTACT;
    },
    isDestinationPaymentForAllPackages() {
      return this.$store.getters.IS_DESTINATION_PAYMENT_FOR_ALL_PACKAGES;
    },
    isDestinationPaymentForSomePackages() {
      return this.$store.getters.IS_DESTINATION_PAYMENT_FOR_SOME_PACKAGES;
    },
    isPrepaidForAllPackages() {
      return this.$store.getters.IS_PREPAID_FOR_ALL_PACKAGES;
    },
    isPrepaidForSomePackages() {
      return this.$store.getters.IS_PREPAID_FOR_SOME_PACKAGES;
    },
    fareNoDiscount() {
      return this.$store.getters.SCHARFFPOINT_FARE_NO_DISCOUNT;
    },
    fareWithDiscount() {
      return this.$store.getters.SCHARFFPOINT_FARE_WITH_DISCOUNT;
    },
    paidDestFareNoDiscount() {
      return this.$store.getters.SCHARFFPOINT_PAID_DEST_FARE_NO_DISCOUNT;
    },
    paidDestFareWithDiscount() {
      return this.$store.getters.SCHARFFPOINT_PAID_DEST_FARE_WITH_DISCOUNT;
    },
    prepaidFareNoDiscount() {
      return this.$store.getters.SCHARFFPOINT_PREPAID_FARE_NO_DISCOUNT;
    },
    prepaidFareWithDiscount() {
      return this.$store.getters.SCHARFFPOINT_PREPAID_FARE_WITH_DISCOUNT;
    },
  },
  mounted() {
    this.fetchCards();
    this.$eventBus.$on("validateScharffPointStep4", () => {
      this.validateStep4();
    });
  },
};
</script>

<style></style>
