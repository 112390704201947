<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <ButtonDefinitive
      @click='callValidation'
    >
      <template v-slot:custom-content>
        <div class='text-md-bold' style='color: #fff;'>Confirmar envío</div>
      </template>
    </ButtonDefinitive>
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "Step4Footer",
  components: { ButtonDefinitive },
  methods: {
    callValidation() {
      this.$eventBus.$emit("validateScharffPointStep4");
    },
  },
  computed: {
    fare() {
      return this.$store.getters.SCHARFFPOINT_FARE;
    },
    order() {
      return this.$store.getters.SCHARFFPOINT_ORDER;
    },
    originalFare() {
      return this.order.Destination
        .filter(item => !item.isShippingPaidAtDestination)
        .map(item => Number(item.fare))
        .reduce((prev, curr) => prev + curr, 0)
    },
    coupon() {
      return this.$store.getters.SCHARFFPOINT_COUPON;
    },
  },
  created() {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
      this.$nextTick(() => {
        let appContainer = document.querySelector('.c-content--app_container');
        if (appContainer) {
          appContainer.classList.add('ios-safari');
        }
      });
    }
  }
};
</script>

<style>
@media screen and (max-device-width: 580px) {
  .c-content--app_container.ios-safari {
    padding-bottom:10em;
  }
}
</style>
