<template>
  <ButtonDefinitive
    label="Continuar"
    @click="next"
  />
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "Step2-Type2-Footer",
  components: { ButtonDefinitive },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    next() {
      const directionsData = this.$store.getters.SCHARFFPOINT_VALIDATE_DIRECTIONS
      if(directionsData.direccion.length > 150) this.showError(`Parece que la <b>dirección</b> ah excedido el límite de 150 caracteres por ${directionsData.direccion.length - 150}. ¿Podrías reducirlo un poco?`)
      else if(directionsData.referencia.length > 150) this.showError(`Parece que la <b>referencia</b> ah excedido el límite de 150 caracteres por ${directionsData.direccion.length - 150}. ¿Podrías reducirlo un poco?`)
      else if (this.validateData() === 'Ok') {
        this.$store.dispatch("SCHARFFPOINT_PROGRESS_NAV_STEP", 3);
        this.$store.dispatch("STYLE_SHOW_PM_QUOTER", false);
        this.$store.dispatch("STYLE_PROGRAMMED_STEP_DIV_THREE_GENERAL");
        // this.$eventBus.$emit("puntoScharffSetOriginAddressDetail");
        
        this.trackEvent('click_puntos_recojo_paso_2_continuar', 'pickup');
      } else {
        // this.showError("Se tiene que completar todos los datos");
        this.showError(this.validateData());
      }
    },
    validateData() {
      return this.$store.getters.SCHARFFPOINT_VALIDATE_STEP_2;
    },
    // validateDireccion() {
    //   return this.$store.getters.SCHARFFPOINT_VALIDATE_DIRECTIONS;
    // },
  },
};
</script>

<style></style>
