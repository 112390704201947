<template>

  <div class='sharf_point_container'>
    <div class='text-xl-bold'>¿De dónde recogemos tu paquete?</div>

    <div class='sharf_point_container-cards'>

      <div class='sharf_point_container-cards-card'>
        <div class='sharf_point_container-cards-card-image'>
          <img src='~@/assets/img/illustrations/punto-sharf.svg'>
        </div>
        <div class='sharf_point_container-cards-card-text'>
          <div class='text-m-bold'>Punto Sharf</div>
          <div class='text-s-regular'>Recogemos tus paquetes de un Punto Sharf y los llevamos a un domicilio u otro punto.</div>
        </div>
        <div class='sharf_point_container-cards-card-layer' @click="setOrderType('1')"></div>
      </div>

      <div class='sharf_point_container-cards-card'>
        <div class='sharf_point_container-cards-card-image'>
          <img src='~@/assets/img/illustrations/domicilio.svg'>
        </div>
        <div class='sharf_point_container-cards-card-text'>
          <div class='text-m-bold'>Domicilio</div>
          <div class='text-s-regular'>Recogemos tus paquetes de tu dirección y los llevamos a un Punto Sharf.</div>
        </div>
        <div class='sharf_point_container-cards-card-layer' @click="setOrderType('2')"></div>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "Step1-Content",
  computed: {
    type: {
      get() {
        return this.$store.getters.SCHARFFPOINT_ORDER_TYPE;
      },
      set(value) {
        this.$store.dispatch("SCHARFFPOINT_ORDER_TYPE", value);
      },
    },
  },
  mounted() {
    this.$eventBus.$on("validateScharffPointStep1", () => {
      this.validateStep1();
    });
  },
  methods: {
    validateStep1() {
      if (this.type === null || this.type === "") {
        this.showError("Debes seleccionar el tipo de envío");
        return false;
      }
      this.$store.dispatch("SCHARFFPOINT_PROGRESS_NAV_STEP", 2);

      this.trackEvent('click_puntos_paso_1_continuar')
      return true;
    },
    setOrderType(orderType) {
      this.$store.dispatch("SCHARFFPOINT_ORDER_TYPE", orderType)
      this.$store.dispatch("SCHARFFPOINT_PROGRESS_NAV_STEP", 2)
    }
  },
};
</script>

<style lang='scss'>
.sharf_point_container {
  padding: 10px 20px 32px 20px;
  display: flex; flex-direction: column; gap: 32px; align-items: center;

  &-cards {
    display: flex; flex-direction: column; gap: 16px; align-items: stretch;

    &-card {
      position: relative;
      display: flex;
      border-radius: 16px;
      background: #FAFAFA;
      min-height: 124px;

      &-image {
        min-width: 124px;
        border-radius: 16px;
        background: #FFEFEF;
        display: flex; justify-content: center; align-items: center;
        img { width: 80px; }
      }

      &-text {
        width: 100%;
        padding: 12px;
        display: flex; flex-direction: column; gap: 4px; align-items: stretch;
      }

      &-layer {
        cursor: pointer;
        border-radius: 16px;
        position: absolute;
        width: 100%; height: 100%;
        background-color: #000;
        opacity: 0;
        transition: .3s;

        &:hover { opacity: .08 }
        &:active { opacity: .20 }
      }

    }
  }
}
</style>
