<template>
  <ButtonDefinitive
    v-if="lstDestination.length === 0"
    label="Continuar"
    @click="next"
  />
  <div v-else class="c-nav c-nav--floating-footer-sticky__spaceButtonPair">
    <ButtonDefinitive
      label="Siguiente"
      @click="next"
    />
    <ButtonDefinitive
      variant='tertiary'
      label="Agregar otro paquete"
      @click="addDestinationAddress()"
    />
  </div>
</template>

<script>
import {
  readFilePSDropOff,
  readFilePSPickUp,
} from "../../../services/massiveLoad";
import {  getScharffPointFare} from "../../../services/fare"
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "Step3-Footer",
  components: { ButtonDefinitive },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    addDestinationAddress() {
      this.$store.dispatch(
        "SCHARFFPOINT_ORDER_SET_INDEX_DESTINATION",
        this.lstDestination.length
      );
      this.$eventBus.$emit("scharffPointClearDataDestinationModal");
      const modalpackage = document.querySelector("#modal-package");
      modalpackage.classList.add("is-active");
      this.$emit("getDefaultContact");
      document.getElementById("scharffPoint-step-3-modal-pop").value = "";

      this.trackEvent('click_puntos_envia_paso_3_agregar_otro_paquete', 'dropoff/puntoapunto')
    },
    async calculateFare(data) {
      const fareRequest = {
        PackageValue: data.productValue,
        PackageWidth: data.packageSize.width,
        PackageHeight: data.packageSize.height,
        PackageDeep: data.packageSize.lengths,
        PackageWeight: data.packageSize.weight,
        PackageType: data.packageSize.type,
        isMoreThan10: false,
        IsServiceExpress: false,
        IsServiceProgrammed: false,
        isServiceScharffPoint: true,
        OriginDistrictId: this.origin.districtId.id,
        DestinationDistrictId: data.destinationAddress.districtId.id,
        ProgrammedServiceDate: null,
        IsUsingSubscription: false,
      };
      
      const  fareResponse = await getScharffPointFare(fareRequest);
      // await this.$store
      //   .dispatch("POST_CALCULATE_FARE", fareRequest)
      //   .then((success) => {
      //     if (success.status === 200) {
      //       fareResponse = success.data.result;
      //     } else {
      //       this.showError(success.data.message);
      //       fareResponse = null;
      //     }
      //   })
      //   .catch((error) => {
      //     const catchMsg = this.catchStandardErrorMsg(error);
      //     this.showError(catchMsg);
      //     fareResponse = null;
      //   });

      return fareResponse;
    },
    async getDepProDisByText(department, province, district) {
      const payload = {
        departamento: department,
        provincia: province,
        distrito: district,
      };

      let res = null;
      await this.$store
        .dispatch("GET_EXCEL_DEP_PRO_DIS", payload)
        .then((success) => {
          if (success.status === 200) {
            res = success.data.result;
          } else {
            this.showError(success.data.message);
            res = null;
          }
        })
        .catch((error) => {
          const catchMsg = this.catchStandardErrorMsg(error);
          this.$swal.close();
          this.showError(catchMsg);
          res = null;
        });

      return res;
    },
    async getScharffPointById(id) {
      let valid = false;
      let scharffPoint = null;
      await this.$store
        .dispatch("GET_SCHARFF_POINT_BY_ID", id)
        .then((success) => {
          if (success.status === 200) {
            valid = true;
            scharffPoint = success.data.result;
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const catchMsg = this.catchStandardErrorMsg(error);
          this.$swal.close();
          this.showError(catchMsg);
        });

      if (valid === true) {
        return scharffPoint;
      }
      return null;
    },
    async processExcelData(destinations) {

      let error = false;
      let totalSumFare = 0;
      for (const item of destinations) {
        const res = await this.getDepProDisByText(item.department, item.province, item.district)
        if (!res) {
          error = true;
          break;
        }

        const servicePackageSize = this.getServicePackageSize(4, item.sizePackage)
        if (!servicePackageSize) {
          this.showError(`Fallo al obtener el tamaño del paquete. ${item.sizePackage}`)
          break
        }

        const data = {
          description: item.description, // del paquete
          destinationAddress: {
            additionalAddress: item.reference,
            contact: {
              contactId: null,
              documentNumber: item.dni,
              email: item.email,
              name: item.name,
              phone: item.cellphone,
            },
            description: item.address, // direccion
            districtId: {
              id: res.districtId,
              text: `${res.departmentText} - ${res.provinceText} - ${res.districtText}`, // dep - pro - dis
            },
            lat: null,
            lng: null,
            puntoScharffId: null,
          },
          fare: null,
          packageSize: {
            height: servicePackageSize.height,
            lengths: servicePackageSize.lengths,
            type: item.sizePackage,
            weight: item.weight,
            width: servicePackageSize.width,
          },
          productValue: item.value,
          isPointPoint: item.isPointPoint,
          isShippingPaidAtDestination: item.isShippingPaidAtDestination,
        };
        if (data.isPointPoint) {
          const scharffPoint = await this.getScharffPointById(item.agent);
          if (!scharffPoint) {
            error = true;
            break;
          }
          data.destinationAddress.puntoScharffId = scharffPoint;
        }

        const fare = await this.calculateFare(data);
        if (!fare) {
          error = true;
          break;
        }

        data.fare = fare.totalFare;

        // Solo suma cuando no es pago en destino
        if (!item.isShippingPaidAtDestination) totalSumFare += fare.totalFare

        await this.$store.dispatch("SCHARFFPOINT_APPEND_DESTINATION", data)
      }

      this.$store.dispatch("SCHARFFPOINT_FARE", totalSumFare);
      if (!error) {
        this.$store.dispatch("SCHARFFPOINT_PROGRESS_NAV_STEP", 4);
        this.$swal.close();
      }
    },
    async next() {
      
      this.showLoading(
        // eslint-disable-next-line max-len
        "Estamos creando tu pedido. Ten en cuenta que esto puede tardar varios segundos más dependiendo de la cantidad de destinos."
      );
      if (this.file) {
        this.$store.dispatch("SCHARFFPOINT_CLEAR_DESTINATION");
        let valid = false;
        let destinations = null;
        const serviceToCall = this.order.type == 1 ? readFilePSDropOff : readFilePSPickUp
        const formData = new FormData();
        this.file.forEach((f) => {
          formData.append("file", f);
          formData.append("originDistrict", this.origin.districtId.id);
        });
        const response = await serviceToCall(formData);
        if (response.status === 200) {
          valid = true;
          destinations = response.data;
          this.trackEvent('click_puntos_envia_paso_3_plantilla_continuar', 'dropoff/puntoapunto')
        } else {
          this.showError(response.data.message);
          this.trackEvent('click_puntos_envia_paso_3_plantilla_continuar_failed', 'dropoff/puntoapunto')
        }

        if (valid === true) {
          await this.$store.dispatch(
            "SCHARFFPOINT_APPEND_DESTINATION",
            destinations
          );
          const sumAll = this.lstDestination
            .filter(item => !item.isShippingPaidAtDestination)
            .map(item => item.fare)
            .reduce((prev, curr) => prev + curr, 0);
          this.$store.dispatch("SCHARFFPOINT_FARE", sumAll);
          this.$store.dispatch("SCHARFFPOINT_PROGRESS_NAV_STEP", 4);
          this.$swal.close();
          // await this.processExcelData(destinations);
        }
      } else if (this.validateData()) {
        this.$store.dispatch("SCHARFFPOINT_PROGRESS_NAV_STEP", 4);
        const sumAll = this.lstDestination
          .filter(item => !item.isShippingPaidAtDestination)
          .map(item => item.fare)
          .reduce((prev, curr) => prev + curr, 0);
        this.$store.dispatch("SCHARFFPOINT_FARE", sumAll);
        this.$swal.close();

        this.trackEvent('click_puntos_envia_paso_3_continuar', 'dropoff/puntoapunto')
      } else {
        this.showError("Agrega un paquete como mínimo.");
      }
    },
    validateData() {
      const list = this.$store.getters.SCHARFFPOINT_ORDER_DESTINATION;
      if (list.length === 0) {
        return false;
      }
      return true;
    },
  },
  computed: {
    lstDestination() {
      return this.$store.getters.SCHARFFPOINT_ORDER_DESTINATION;
    },
    file() {
      return this.$store.getters.SCHARFFPOINT_FILE;
    },
    origin() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    order() {
      return this.$store.getters.SCHARFFPOINT_ORDER;
    },
  },
};
</script>

<style></style>
