<template>
  <article class="c-content--sendPoint c-scrollbar" :key="forceRerenderKey">
    <div class="c-content--app_container c-content--app--topnav container_main_content" @scroll="handleScroll" ref="refContainer_main_content">
      <p class='text-xl-bold' style='padding-bottom: 8px;'>¿Quién envía?</p>
      <DestinationContactButton
        @click="openContactModal('origin')"
        :destinationContact="originContact"
        missingErrorMessage="Escoge un contacto."
      />
      <p class='text-xl-bold' style='padding: 48px 0 16px 0;'>¿De qué Punto Sharf recogemos tus paquetes?</p>
      <p v-if="predeterminedPointList.length > 0" class='text-l-regular' style='padding: 16px 0;'>Mis Puntos Sharf favoritos</p>
      <div v-if="predeterminedPointList.length > 0" class='prev_and_nextButton'>
        <div class='arrow' :class="{ 'disabled' : predeterminedPointList.findIndex(item => item.puntoScharffId === currentlyShowing) === 0 }" @click="scrollCarousel('prev')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.03 4.47a.75.75 0 0 1 .073.976l-.073.084L9.561 12l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073-7-7a.75.75 0 0 1-.073-.976l.073-.084 7-7a.75.75 0 0 1 1.06 0" fill="#121212"/></svg>
        </div>
        <div class='arrow' :class="{ 'disabled' : predeterminedPointList.findIndex(item => item.puntoScharffId === currentlyShowing) === predeterminedPointList.length - 1 }" @click="scrollCarousel('next')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.97 19.53a.75.75 0 0 1-.073-.976l.073-.084L14.439 12l-6.47-6.47a.75.75 0 0 1-.072-.976l.073-.084a.75.75 0 0 1 .976-.073l.084.073 7 7a.75.75 0 0 1 .073.976l-.073.084-7 7a.75.75 0 0 1-1.06 0" fill="#121212"/></svg>
        </div>
      </div>
      <div v-if="predeterminedPointList.length > 0" class='favorites_scharff_point_list' style='padding-bottom: 16px; padding-top: 8px;'>
        <div
          v-for='favoritePoint in predeterminedPointList'
          :ref="`${favoritePoint.puntoScharffId}fav`"
          class='c-content--pointSharff__content scharff_point_card' :class="{ 'selected' : scharffPointSelectedId === favoritePoint.puntoScharffId }"
          @click="selectScharffPoint(favoritePoint, 'favoriteList'), trackEvent('click_puntos_envia_elegir_punto', 'dropoff')"
        >
          <div class="c-content--pointSharff__content__title">
              <span class='text-m-bold'>{{ favoritePoint.puntoScharffName }}</span>
              <div class='icon-left-side'>
                <div v-if="favoritePoint.name.toLowerCase().includes('real plaza')">
                  <img style="width: 25px;" src="~@/assets/scharff-points-logos/Logo-RealPlaza.svg">
                </div>
                <div @click='toggleFavorite(favoritePoint)' class='heard_button favorite'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.002 5.925a5.4 5.4 0 0 0-2.236-1.498c-3.3-1.17-6.386 1.17-6.734 4.45-.489 4.944 4.803 7.984 8.105 10.695.371.31.914.395 1.362.21.215-.082.388-.237.563-.38 3.33-2.73 8.59-5.866 7.871-10.845-.532-3.478-4.163-5.431-7.294-3.88a5.4 5.4 0 0 0-1.637 1.248" stroke="#737373" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
              </div>
          </div>
          <div class="c-content--pointSharff__content__paragraph" style="margin-bottom: 3px" >
            {{ favoritePoint.address }}
          </div>
          <div class="c-content--pointSharff__content__paragraph" style="color: #747474" >
            {{`Horario de atención: ${favoritePoint.openTime.substring(0,5)} - ${favoritePoint.closeTime.substring(0, 5)}`}}
          </div>
        </div>
      </div>
      <p class='text-l-regular' style='padding-bottom: 8px; padding-top: 16px;'>Busca un Punto Sharf</p>
      <CustomSearchSelect
        :isRequired='false'
        @setSelectedDisctrict="onSelectedDistrict"
        v-model="destinationDistrictId"
        :repoSearch="getScharffDistrics"
        label="Distrito"
        placeholder="Busca por departamento, provincia o distrito"
      />
    </div>

    <div class="c-content--pointSharff-slider" :style='pointSharffSliderStyles'>
      <div
        v-for="(item, key) in lstScharffPoint"
        @click="selectScharffPoint(item, 'generalList'), trackEvent('click_puntos_envia_elegir_punto', 'dropoff')"
        :key="key"
        :ref="item.ScharffPointId"
        :class="
          !item.Selected
            ? `c-content--pointSharff-inline`
            : `c-content--pointSharff-inline--selected`
        "
      >
        <div class="c-content--pointSharff">
          <div class="c-content--pointSharff__content">
            <div class="c-content--pointSharff__content__title">
              <span class='text-m-bold'>{{ item.Name }}</span>
              <div class='icon-left-side'>
                <div v-if="item.Name.toLowerCase().includes('real plaza')">
                  <img style="width: 25px;" src="~@/assets/scharff-points-logos/Logo-RealPlaza.svg">
                </div>
                <div @click='toggleFavorite(item)' class='heard_button' :class="{'favorite' : predeterminedPointList.map(point => point.puntoScharffId).includes(item.ScharffPointId)}">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.002 5.925a5.4 5.4 0 0 0-2.236-1.498c-3.3-1.17-6.386 1.17-6.734 4.45-.489 4.944 4.803 7.984 8.105 10.695.371.31.914.395 1.362.21.215-.082.388-.237.563-.38 3.33-2.73 8.59-5.866 7.871-10.845-.532-3.478-4.163-5.431-7.294-3.88a5.4 5.4 0 0 0-1.637 1.248" stroke="#737373" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
              </div>
            </div>
            <div class="c-content--pointSharff__content__paragraph" style="margin-bottom: 3px" >
              {{ item.Address }}
            </div>
            <div class="c-content--pointSharff__content__paragraph" style="color: #747474" >
              {{`Horario de atención: ${item.OpenTime.substring(0,5)} - ${item.CloseTime.substring(0, 5)}`}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="lstScharffPoint.length === 0 && districtId" style="padding: 0px 15px">
      <h2> Lo sentimos, por ahora no contamos con Puntos Sharf en esta ubicación :(</h2>
    </div>

    <button
      id="setSchaffPointMarker"
      v-on:click="setScharffPointMarkedSelect()"
      style="display: none"
    ></button>
    <input id="scharffPointMarkerId" style="display: none" />
  </article>
</template>

<script>
import M from "materialize-css";
import InputDistrict from "../../General/InputDistrict.vue";
import DestinationContactButton from '../../Button/DestinationContactButton.vue'
import { deleteFavoriteLocation } from '../../../services/client'
import CustomSearchSelect from '@/components/Form/CustomSearchSelect.vue'
import { getScharffDistrics } from '@/services/fare';

export default {
  name: "Step2-Type1-Content",
  components: {
    InputDistrict,
    DestinationContactButton,
    CustomSearchSelect,
  },
  props: {
    predeterminedPointList: {
      type: Array,
      default: () => []
    },
    step: {
      type: Number,
      default: ''
    }
  },
  data() {
    return {
      isOriginFocused: false,
      lstScharffPoint: [],
      scharffPointSelectedId: '',
      originAddress: {
        description: "",
        lat: null,
        lng: null,
        favorite: false,
      },
      districtId: null,
      currentlyShowing: null,

      pointSharffSliderStyles: {},
      destinationDistrictId: '',

      forceRerenderKey: 0,
    };
  },
  methods: {
    getScharffDistrics,
    forceRerender() {
      this.forceRerenderKey += 1
    },
    handleScroll() {
      const mainContent = this.$refs.refContainer_main_content

      if (window.innerWidth > 767) return this.pointSharffSliderStyles = { bottom: `auto` }

      const height = window.innerHeight

      let listSpace = this.predeterminedPointList.length > 0 ? 0 : 200

      if (height > 900) return this.pointSharffSliderStyles = { bottom: `100px` }

      // const windowHeight = window.innerHeight;
      // const screenHeight = screen.height;

      // const browserAreaHeight = screenHeight - windowHeight;
      // console.log('Altura del área del navegador:', windowHeight);
      if (height < 876 && mainContent.scrollTop === 0) this.pointSharffSliderStyles = { bottom: `-100px` }
      if (height < 385) return this.pointSharffSliderStyles = { bottom: `${mainContent.scrollTop + listSpace - 460}px` }
      if (height < 450) return this.pointSharffSliderStyles = { bottom: `${mainContent.scrollTop + listSpace - 420}px` }
      if (height < 533) return this.pointSharffSliderStyles = { bottom: `${mainContent.scrollTop + listSpace - 350}px` }
      if (height < 618) return this.pointSharffSliderStyles = { bottom: `${mainContent.scrollTop + listSpace - 300}px` }
      if (height < 733) return this.pointSharffSliderStyles = { bottom: `${mainContent.scrollTop + listSpace - 200}px` }
      if (height < 876) return this.pointSharffSliderStyles = { bottom: `${mainContent.scrollTop + listSpace - 100}px` }
      if (height < 1000) return this.pointSharffSliderStyles = { bottom: `${mainContent.scrollTop + listSpace - 50}px` }
    },
    async setScharffPointMarkedSelect() {
      const id = document.getElementById("scharffPointMarkerId").value;
      document.getElementById(id).click();
    },
    editOriginContact() {
      this.openContactModal("origin");
      this.trackEvent('click_puntos_envia_paso_2_editar_contacto_btn', 'dropoff');
    },
    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);

            const modalContactFavorite = document.querySelector("#modal-contact-origin")
            const instance = M.Modal.init(modalContactFavorite, { dismissible: false })
            instance.open()

          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
        this.trackEvent('click_puntos_envia_paso_2_contacto_btn', 'dropoff');
    },
    getScharffPointLst() {
      this.map.clearMarkersScharffPoint();
      this.$store
        .dispatch("GET_SCHARFF_POINT", this.districtId.id)
        .then((response) => {
          const targetNames = ['counter sharf miraflores', 'counter sharf benavides', 'real plaza']
          const respFormated = response.map(item => ({
            Address: item.Address,
            CloseTime: item.CloseTime,
            DepartamentoId: item.DepartamentoId,
            DepartamentoTexto: item.DepartamentoTexto,
            DistritoId: item.DistritoId,
            DistritoTexto: item.DistritoTexto,
            Location: {
              Lat: item.Location.Lat,
              Lng: item.Location.Lng,
            },
            Name: item.Name,
            OpenTime: item.OpenTime,
            ProvinciaId: item.ProvinciaId,
            ProvinciaTexto: item.ProvinciaTexto,
            ScharffPointId: item.ScharffPointId,
            State: item.State,
            Selected: false
          }))

          // Mover los elementos especificados al principio del arreglo
          respFormated.sort((a, b) => {
            const nombreA = a.Name.toLowerCase()
            const nombreB = b.Name.toLowerCase()

            if (targetNames.some(keyword => nombreA.includes(keyword)) && !targetNames.some(keyword => nombreB.includes(keyword))) return -1
            if (!targetNames.some(keyword => nombreA.includes(keyword)) && targetNames.some(keyword => nombreB.includes(keyword))) return 1
            return 0
          });
          this.lstScharffPoint = respFormated
        })
        .then(() => {
          this.lstScharffPoint.forEach((item) => {
            this.map?.addMarkerScharffPoint(
              item.Location,
              item.Name,
              item.Name,
              item
            );
          });
          this.initSelects();
        })
        .catch(e => console.log(e))

        this.trackEvent('puntos_envia_paso_2_carga_lista_puntos', 'dropoff')
    },
    onSelectedDistrict(district) {
      if(!district.id) return
      this.districtId = district
      this.getScharffPointLst()
    },

    setPointFromOutside(pointData) {
      setTimeout(() => {
        this.selectScharffPoint(pointData, 'favoriteList')
      }, 200)
    },
    setPointToShow() {
      setTimeout(() => {
        this.currentlyShowing = this.predeterminedPointList.length > 0 ? this.predeterminedPointList[0].puntoScharffId : null
      }, 200)
    },
    selectScharffPoint(item, list) {
      this.scharffPointSelectedId = item?.ScharffPointId || item?.puntoScharffId

      this.lstScharffPoint.forEach((point) => {
        point.Selected = false;
      });

      let pointFound = this.lstScharffPoint.find(point => point.ScharffPointId === this.scharffPointSelectedId)
      if (pointFound) pointFound.Selected = true

      if (!pointFound) {
        pointFound = {
          Address: item.address,
          CloseTime: item.closeTime,
          DepartamentoId: item.departamento,
          DepartamentoTexto: item.departmentName,
          DistritoId: item.distrito,
          DistritoTexto: item.districtName,
          Location: item.location,
          Name: item.puntoScharffName,
          OpenTime: item.openTime,
          ProvinciaId: item.provincia,
          ProvinciaTexto: item.provinceName,
          ScharffPointId: item.puntoScharffId,
          Selected: true,
          State: 'ACT',
        }
      }

      this.$store.dispatch("SCHARFFPOINT_ORDER_ORIGIN_SCHARFFPOINT", pointFound)
      this.$store.dispatch("SCHARFFPOINT_ORDER_ORIGIN_DISTRICT", { id: pointFound.DistritoId })
      const position = { lat: pointFound.Location.Lat, lng: pointFound.Location.Lng }
      this.map.centerMap(position, 18)
      this.scrollTo(pointFound.ScharffPointId, list)
    },
    scrollTo(refId, list) {
      let reference
      if (list === 'generalList') reference = this.$refs[refId]
      if (list === 'favoriteList') {
        reference = this.$refs[`${refId}fav`]
        this.currentlyShowing = refId
      }

      if (reference) {
        reference[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },

    async toggleFavorite(SPData) {
      this.showLoading()
      try {
        const sp_id = SPData?.puntoScharffId || SPData?.ScharffPointId
        const favoriteSP = this.predeterminedPointList.find(sp => sp.puntoScharffId === sp_id)

        if (favoriteSP?.puntoScharffId){
          await deleteFavoriteLocation(favoriteSP.favoriteLocationId)
        } else {
          const payload = {
            address: SPData?.Address || SPData?.address,
            addressAditional: '',
            distrito: SPData?.DistritoId || SPData?.distrito,
            name: 'Punto Sharf',
            isPredetermined: false,
            puntoScharffId: SPData?.ScharffPointId || SPData?.puntoScharffId
          }

          await this.$store.dispatch('POST_FAVORITE_ADDRESS', payload)
        }

        if (this.districtId.id) this.getScharffPointLst()
        const resp = await this.$store.dispatch('GET_FAVORITE_LOCATIONS')
        this.$emit('update-predeterminedPointList', resp)

        setTimeout(() => {
          this.lstScharffPoint.forEach(point => point.Selected = false)
          this.$store.dispatch("SCHARFFPOINT_ORDER_ORIGIN_SCHARFFPOINT", null)
          this.scharffPointSelectedId = ''
        }, 100)

        this.$swal.close()
      } catch (e) {
        console.log(e)
        const msg = e?.data?.message || 'Ocurrió un error.'
        this.showError(msg)
      }
    },
    scrollCarousel(movement) {
      try {
        let currentIdShowed = this.currentlyShowing

        if (!this.currentlyShowing) {
          currentIdShowed = this.scharffPointSelectedId || this.predeterminedPointList[0].puntoScharffId
        }

        const currentIndex = this.predeterminedPointList.findIndex(item => item.puntoScharffId === currentIdShowed)

        // Determinar el índice del siguiente o el previo elemento según la acción
        let adjacentIndex
        if (movement === 'next') {
          adjacentIndex = currentIndex + 1
        } else if (movement === 'prev') {
          adjacentIndex = currentIndex - 1
        }

        // Verificar si el índice calculado está dentro de los límites del array
        if (adjacentIndex >= 0 && adjacentIndex < this.predeterminedPointList.length) {
          this.scrollTo(this.predeterminedPointList[adjacentIndex].puntoScharffId, 'favoriteList')
        }
      } catch (e) {
        console.log({e})
      }
    }
  },
  mounted() {
    this.$nextTick(() => {});
  },
  computed: {
    originContact() {
      return this.$store.getters.SCHARFFPOINT_ORIGIN_CONTACT;
    },
    map() {
      return this.$store.getters.SCHARFFPOINT_MAP;
    },
  },
  watch: {
  predeterminedPointList() {
    this.$nextTick(() => {
      this.handleScroll();
    });
  }
}
};
</script>
<style lang='scss' scoped>
.favorites_scharff_point_list {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 0 10px 10px 10px;

  .c-content--pointSharff__content {
    padding: 16px;
    min-width: 264px;
    border-radius: 16px;
    background: #FAFAFA;
  }
  @media screen and (min-width: 768px) {
    overflow-x: hidden;
  }
}
.container_main_content {
  @media screen and (max-width: 768px) {
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 450px;
  }

  .prev_and_nextButton {
    @media screen and (max-width: 768px) {
      display: none;
    }

    display: flex;
    gap: 16px;

    .arrow {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 32px; height: 32px;
      border-radius: 8px; border: 1px solid #121212;
      transition: .3s;

      &:hover:not(.disabled) { background: rgba(0, 0, 0, 0.08) }
      &:active:not(.disabled) { background: rgba(0, 0, 0, 0.16) }

      &.disabled {
        border: 1px solid #A0A0A0;
        cursor: not-allowed;

        svg { path { fill: #A0A0A0 } }
      }
    }
  }
}
.newCounter {
  border-radius: 8px;
  border: 1px solid #121212;
  background: #FFF;
  box-shadow: 2px 2px 0px 0px #FF7177, 3px 3px 0px 0px #121212, 5px 5px 0px 0px #FF565D, 6px 6px 0px 0px #121212;
  .c-content--pointSharff {
    border-radius: 8px;
    height: 100%;
    margin: 0;
    box-shadow: none !important;
    background: #FAFAFA !important;
  }
}

.c-content--pointSharff__content {
  width: 100%;
  .c-content--pointSharff__content__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    .icon-left-side {
      display: flex;
      gap: 4px;

      .heard_button {
        cursor: pointer;
        display: flex;
        min-width: 24px;
        min-height: 24px;
        padding: 8px;
        margin: -8px;
        transition: .3s;
        border-radius: 12px;

        svg { transition: .3s }

        &:hover {
          transform: scale(1.05);
          background: rgba(0, 0, 0, 0.08);
        }

        &.favorite { svg { fill: #FF565D; path { stroke: #FF565D } } }

        &:active {
          transform: scale(0.95);
          background: rgba(0, 0, 0, 0.16);
        }
      }
    }
    .newButton {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #FFB126;

      color: #FFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
}

.scharff_point_card {
  cursor: pointer;
  transition: .3s;

  &:active {
    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.10) !important;
    background: rgba(0, 0, 0, 0.20) !important;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.selected {
    // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.20);
    border: 2px solid #121212;
  }
}

@media (min-width: 769px) {
  .newCounter {
    margin: 0 10px 15px 0;
    .c-content--pointSharff {
      margin: 0;
      padding-bottom: 15px;
    }
  }
}
</style>