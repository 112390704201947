<template>
  <ButtonDefinitive
    label="Continuar"
    @click="next"
  />
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "Step2-Type1-Footer",
  components: { ButtonDefinitive },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    next() {
      if (this.validateData() === 'Ok') {
        this.$store.dispatch("SCHARFFPOINT_PROGRESS_NAV_STEP", 3);
        this.$store.dispatch("STYLE_MAP_SAIO", true);
        this.$store.dispatch("STYLE_SHOW_PM_QUOTER", false);
        this.$store.dispatch("STYLE_PROGRAMMED_STEP_DIV_THREE_GENERAL");
        
        this.trackEvent('click_puntos_envia_paso_2_continuar', 'dropoff');
      } else {
        this.showError(this.validateData());
      }
    },
    validateData() {
      return this.$store.getters.SCHARFFPOINT_VALIDATE_STEP_2;
    },
  }
};
</script>

<style></style>
